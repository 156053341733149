import React, { FC, useCallback, useEffect, useState } from "react";
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, StripeElementsOptions } from '@stripe/stripe-js';
import { Order } from "../../types/Order";
import { PaymentResult } from "../../types/PaymentResult";
import {
	Error,
	FetchHelper,
	LoadingLottie,
	LoadingStatus,
	useAuthContext,
} from "@icasdigital/icas.core.reactcomponents";
import { CheckoutForm } from "./CheckoutForm";

type PDProps = {
	order: Order;
	stripePromise: Stripe | PromiseLike<Stripe | null> | null;
};

export const PaymentDetails: FC<PDProps> = ({
	order,
	stripePromise
}) => {
	const { handleUnauth } = useAuthContext();
	const [paymentResult, setPaymentResult] = useState<PaymentResult>();
	const [retryAfterError, setRetryAfterError] = useState<boolean>(false);
	const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(
		LoadingStatus.IsNotLoading
	);
	const [stripeOptions, setStripeOptions] = useState<StripeElementsOptions>();

	// Start the payment journey 
	const startPaymentJourney = useCallback(async () => {
		// Call server-side code to submit payment to Stripe etc.
		// This will create payment intent, or or retrieve the existing one
		// if it has already been created for the order
		setLoadingStatus(LoadingStatus.IsLoading);
		const fetchURL = "/payments";
		const payment = {
			order: {
				description: order.description,
				origin: order.origin,
				totalAmount: order.outstandingAmount,
				orderNumber: order.orderNumber,
				orderId: order.id,
			},
		};
		const fetchResponse = await FetchHelper.post(
			fetchURL,
			handleUnauth,
			payment as unknown as BodyInit
		);
		if (fetchResponse.ok) {
			// Outcome will be in payment result
			const data: PaymentResult = fetchResponse.body as PaymentResult;
			return setPaymentResult(data); 
		} else if (fetchResponse.status === 401) {
			handleUnauth();
			setLoadingStatus(LoadingStatus.EndedWithError);
		} else {
			setLoadingStatus(LoadingStatus.EndedWithError);
		}
	}, [handleUnauth, order]);

	useEffect(() => {
		startPaymentJourney();
	}, [startPaymentJourney]);

	useEffect(() => {
		if (paymentResult?.stripeClientSecret) {
			const options = {
				clientSecret: paymentResult?.stripeClientSecret,
				// Fully customizable with appearance API.
				appearance: {/*...*/},
			};
			setStripeOptions(options);
		}
	}, [paymentResult]);

	useEffect(() => {
		if (stripePromise && stripeOptions) {
			setLoadingStatus(LoadingStatus.LoadedSuccessfully);
		}
	}, [stripePromise, stripeOptions])

	const handleErrorRetry = () => {
		setRetryAfterError(true);
		setLoadingStatus(LoadingStatus.LoadedSuccessfully);
	};

	if (loadingStatus === LoadingStatus.LoadedSuccessfully) {
		return (
			<Elements stripe={stripePromise} options={stripeOptions}>
			<div className="payment-details">
				<div style={{ display: "inline-flex", alignItems: "center" }}>
					<h3 className="with-line" style={{ margin: "0", fontSize: "1.35em" }}>
						Payment Details:
					</h3>
				</div>
				<div>
					<CheckoutForm
						orderNumber={order.orderNumber}
						orderOrigin={order.origin} 
						orderOutstandingAmount={order.outstandingAmount}
					/>
				</div>
			</div>
			</Elements>
		);
	} 
	if (loadingStatus === LoadingStatus.IsLoading) {
		return (
			<div className="payment-details">
				<div
					style={{
						display: "inline-flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<LoadingLottie style={{ height: "50%" }} />
				</div>
				;
			</div>
		);
	} 
	if (loadingStatus === LoadingStatus.EndedWithError && retryAfterError) {
		return (
			<div className="payment-details">
				<Error
					errorMessage={`An unexpected error has occured. Please select Retry to refresh the page.`}
					buttonText="Retry"
					handleClick={handleErrorRetry}
				/>
			</div>
		);
	} 
	return <></>;
};
