import React, { FC} from "react";
import {
	LoadingStatus,
	Layout,
	LoadingLottie,
	useAuthContext,
} from "@icasdigital/icas.core.reactcomponents";
import { Outlet } from "react-router";



export const App: FC = (): JSX.Element => {
	const { currentLoading } = useAuthContext();

	const isLoaded = currentLoading === LoadingStatus.EndedWithError ||
		currentLoading === LoadingStatus.LoadedSuccessfully;

	if (isLoaded) {
		 return ( <Layout>
			<div id="title-container">
				<h1 id="app-title">My Orders</h1>
			</div>
			<Outlet />
		</Layout>
		 )
	}

	return (
		<Layout>
			<div id="title-container">
				<h1 id="app-title">My Orders</h1>
			</div>
			<LoadingLottie style={{ height: "20em", margin: "auto" }} />
		</Layout>
	);
};
