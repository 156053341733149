import { AnchorButton } from "@icasdigital/icas.core.reactcomponents";
import React, { FC } from "react";
import { PaymentFailedLottie } from "./PaymentFailedLottie";

type PaymentFailedProps = {
	orderNumber: string
}

export const PaymentFailed: FC<PaymentFailedProps> = ({
	orderNumber
}) => {
	return (
		<>
			<PaymentFailedLottie style={{ height: "10em"}} />
			<div>
				<h3>Payment was not taken and the order could not be completed.</h3>
				<AnchorButton
					href={`/payment?orderNumber=${orderNumber}`}
					text="Retry"
					style={{ justifySelf: "center", width: "9rem" }}
				/>
			</div>
		</>
	);
};
