import { Elements } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import React, { FC } from "react";
import { PaymentStatusOutcome } from "./PaymentStatusOutcome";

type PSProps = {
	orderNumber: string;
	orderOrigin?: string;
	stripePromise: Stripe | PromiseLike<Stripe | null> | null;
	payment_intent_client_secret: string;
};

export const PaymentStatus: FC<PSProps> = ({
	orderNumber,
	orderOrigin,
	stripePromise,
	payment_intent_client_secret
}) => {
	const stripeOptions = {
		clientSecret: payment_intent_client_secret,
		appearance: {/*...*/},
	};
	return (
		<div className="payment-container">
			<div className="payment-header">
				<Elements stripe={stripePromise} options={stripeOptions}>
					<PaymentStatusOutcome
						orderNumber={orderNumber}
						orderOrigin={orderOrigin}
						payment_intent_client_secret={payment_intent_client_secret}
					/>
				</Elements>
			</div>
		</div>
	)
}
