/*
 * Manage user state (fetching user)
 * Fetch data (orders)
 * Store state
 * Functions for handling actions in child components (e.g. pay now)
 */

import "./OrderContainer/ContainerStyle.css";
import { Tabs } from "../components/Tabs/Tabs";

export const TabContainer = () => {
	return <Tabs />;
};
