/*
 * Manage user state (fetching user)
 * Fetch data (orders)
 * Store state
 * Functions for handling actions in child components (e.g. pay now)
 */
import React from "react";
import {
	Error,
	LoadingLottie,
	LoadingStatus,
} from "@icasdigital/icas.core.reactcomponents";
import { OpenOrderItem } from "../../components/OrderItem/OpenOrderItem";
import "./ContainerStyle.css";
import { useGetOrders } from "../useGetOrders";
import { FulfilledOrderItem } from "../../components/OrderItem/FulfilledOrderItem";

export enum OrderType {
	Open = "open",
	Fulfilled = "fulfilled",
}

export const OrderContainer = ({ orderType }: { orderType: OrderType }) => {

	const { orders, haveError, loadingStatus, handleErrorRetry } = useGetOrders({
		orderType,
	});

	const noOrders =
		loadingStatus === LoadingStatus.LoadedSuccessfully && orders.length <= 0;


	if (haveError) {
		return (
			<div className="app-container app-container__order">
				<div className="order-container">
					<Error
						errorMessage="Request to load orders failed."
						handleClick={handleErrorRetry}
					/>
				</div>
			</div>
		);
	}
	if (noOrders) {
		return (
			<div className="app-container app-container__order">
				<div className="order-container">
					<div className="no-orders">
						<h3>You have no {orderType} orders</h3>
					</div>
				</div>
			</div>
		);
	}

	if (loadingStatus === LoadingStatus.IsLoading) {
		return (
			<div className="app-container app-container__order">
				<div className="order-container">
					<LoadingLottie />
				</div>
			</div>
		);
	}

	const orderLines = orders.map((order) => (
		order.isFulfilled? <FulfilledOrderItem key={order.id} order={order} /> : <OpenOrderItem key={order.id} order={order} />
	));

	return (
		<div className="app-container app-container__order">
			<div className="order-container">{orderLines}</div>
		</div>
	);
};
