import React, { useCallback, useEffect, useState } from "react";
import {
	FetchHelper,
	useAuthContext,
} from "@icasdigital/icas.core.reactcomponents";
import "./PaymentContainerStyle.css";
import { PaymentAuthDetails } from "../../types/PaymentAuthDetails";
import { PaymentStatus } from "../../components/PaymentStatus/PaymentStatus";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Payment } from "../../components/Payment/Payment";
import { redirect, useSearchParams } from "react-router-dom";

export const PaymentContainer = () => {
const [ searchBarParams ] = useSearchParams();
const orderNumber = searchBarParams.get('orderNumber');
const origin = searchBarParams.get('origin') ?? undefined;
const payment_intent_client_secret = searchBarParams.get('payment_intent_client_secret')


	//#region State Management
	const [stripePublishableKey, setStripePublishableKey] = useState<string>();
	const [stripePromise, setStripePromise] = useState<
		Stripe | PromiseLike<Stripe | null> | null
	>(null);
	const { handleUnauth } = useAuthContext();
	//#endregion

	//#region Request Handlers
	// Retrieve the publishable key from services
	const fetchStripePublishableKey = useCallback(async () => {
		const fetchUrl = "/payments/stripePublishableKey";
		const fetchResponse = await FetchHelper.getJSON(fetchUrl, handleUnauth);
		if (fetchResponse.ok) {
			const data: PaymentAuthDetails =
				fetchResponse.body as PaymentAuthDetails;
			setStripePublishableKey(data.stripePublishableKey);
		}
	}, [handleUnauth]);
	//#endregion

	//#region Side-effect handlers
	useEffect(() => {
		fetchStripePublishableKey();
	}, [fetchStripePublishableKey]);

	useEffect(() => {
		const loadStripePromise = async () => {
			if (stripePublishableKey) {
				const stripe = await loadStripe(stripePublishableKey);
				setStripePromise(stripe);
			}
		}
		loadStripePromise()
	}, [stripePublishableKey]);
	//#endregion

	//#region Renderers
	if (!orderNumber) {
		return redirect("/");
	}
	if (payment_intent_client_secret) {
		/* Returning from Stripe after payment */
		return (
			<PaymentStatus
				orderNumber={orderNumber}
				orderOrigin={origin}
				stripePromise={stripePromise}
				payment_intent_client_secret={payment_intent_client_secret}
			/>
		);
	}

	return <Payment orderNumber={orderNumber} stripePromise={stripePromise} />;

	//#endregion
};
