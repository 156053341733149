import React, { FC } from "react";
import { renderGBP, renderDateString } from "../../utils/Formatting";
import { ImageIcon } from "../ImageIcon";
import "./OrderItemStyle.css";

export enum ColumnType {
	string,
	date,
	money,
}

type ColumnProps = {
	objectName: string;
	displayName: string;
	type: ColumnType;
};

type OIDProps = {
	record: Record<string, object[] | string | number | boolean | Date>;
	columns: ColumnProps[];
	icon?: string;
	style?: object;
};

export const OrderItemDetail: FC<OIDProps> = ({
	record,
	columns,
	icon,
	style,
}) => {

	const renderedDetailRows = columns.map((column) => {
		const { objectName, type, displayName} = column;
		let value = record[objectName];
		let className= "";
		if (type === ColumnType.money) {
			const amount: any = value ?? 0 / 100;
			value = renderGBP(amount);
			className = "order-detail-key"
		}
		if (type === ColumnType.date) {
			value = renderDateString(value as string);
		}
		return (
			<li key={displayName} className={className}>
				{displayName}: {value}
			</li>
		);
	});

	const detailsDiv = icon ? (
		<div className="image-details">
			<ImageIcon image={icon} alt={""} height="5em" />
			<ul>{renderedDetailRows}</ul>
		</div>
	) : (
		<ul>{renderedDetailRows}</ul>
	);

	return (
		<div className="order-details" style={style}>
			<h3>{record.name}</h3>
			{detailsDiv}
		</div>
	);
};
