import * as TabsComponent from "@radix-ui/react-tabs";

import "./TabsStyle.css";

import {
	OrderContainer,
	OrderType,
} from "../../containers/OrderContainer/OrderContainer";

const OpenOrder = "open";
const closedOrder = "closed";

export const Tabs = () => (
		<TabsComponent.Root defaultValue={OpenOrder} className="tabs-root">
			<TabsComponent.List className="tabs-list">
				<TabsComponent.Trigger value={OpenOrder} className="tabs-trigger">
					Open
				</TabsComponent.Trigger>
				<TabsComponent.Trigger value={closedOrder} className="tabs-trigger">
					Fulfilled
				</TabsComponent.Trigger>
			</TabsComponent.List>
			<TabsComponent.Content value={OpenOrder}>
				<OrderContainer orderType={OrderType.Open} />
			</TabsComponent.Content>
			<TabsComponent.Content value={closedOrder}>
				<OrderContainer orderType={OrderType.Fulfilled} />
			</TabsComponent.Content>
		</TabsComponent.Root>
);
