import { Order } from "../../types/Order";
import { Button } from "@icasdigital/icas.core.reactcomponents";
import { ColumnType, OrderItemDetail } from "./OrderItemDetail";
import "./OrderItemStyle.css";
import OrderIcon from "../../assets/orderIcon.png";
import { useNavigate } from "react-router-dom";

type OIProps = {
	order: Order;
};

const columns = [
	{
		objectName: "orderNumber",
		displayName: "Reference",
		type: ColumnType.string,
	},
	{
		objectName: "createdOn",
		displayName: "Order Placed On",
		type: ColumnType.date,
	},
	{
		objectName: "outstandingAmount",
		displayName: "Amount Due",
		type: ColumnType.money,
	},
];

export const OpenOrderItem = ({ order }: OIProps) => {
	const paymentRoute = `/payment?orderNumber=${order.orderNumber}`;
	const navigate = useNavigate();

	const handlePayNow = () => {
		return navigate(paymentRoute);
	};

	return (
		<div className="order">
			<OrderItemDetail record={order} columns={columns} icon={OrderIcon} />
			{!order.paymentIsProcessing ? (
				<Button id="pay" text="Pay Now" onClick={handlePayNow} />
			) : (
				<div className="processing-label">Processing</div>
			)}
		</div>
	);
};
