import { Stripe } from "@stripe/stripe-js";
import {
	AnchorButton,
	Error,
	FetchHelper,
	FetchResponse,
	LoadingLottie,
	useAuthContext,
} from "@icasdigital/icas.core.reactcomponents";
import React, { useCallback, useEffect, useState } from "react";
import { Order } from "../../types/Order";
import { ErrorNotFound } from "../ErrorNotFound";
import { CostBreakdown } from "./CostBreakdown";
import { PaymentDetails } from "./PaymentDetails";
import { redirect } from "react-router-dom";

type PaymentProps = {
	orderNumber: string;
	stripePromise: Stripe | PromiseLike<Stripe | null> | null;
};

export const Payment = ({ orderNumber, stripePromise }: PaymentProps) => {
	//#region State Management
	const { userId, handleUnauth } = useAuthContext();
	const [haveError, setHaveError] = useState(false);
	const [notFound, setNotFound] = useState(false);
	const [order, setOrder] = useState<Order | null>(null);
	//#endregion

	//#region Request Handlers
	// Retrieve the order from services
	const getOrder = useCallback(() => {
		if (!haveError) {
			const handleResponse = (fetchResponse: FetchResponse): void => {
				if (fetchResponse.ok) {
					const data: Order = fetchResponse.body as Order;
					return setOrder(data);
				} 
				if (fetchResponse.status === 401) {
					return handleUnauth();
				} 
				setHaveError(true);
				setNotFound(fetchResponse.status === 404);
			};

			async function getOrder(ref: string) {
				const fetchURL = `/members/${userId}/orders/${orderNumber}`;
				const response = await FetchHelper.getJSON(fetchURL, handleUnauth);
				handleResponse(response);
			}

			if (userId && orderNumber) {
				getOrder(orderNumber);
			} else {
				// Redirect done in renderer if order number is missing
			}
		}
	}, [handleUnauth, orderNumber, userId, haveError]);
	//#endregion

	//#region Side-effect handlers
	useEffect(() => {
		getOrder();
	}, [getOrder]);

	//#region Event handlers
	const handleErrorRetry = () => {
		setHaveError(false);
	};
	//#endregion

	const noOrderToPay =
		order !== null && (order.isFulfilled || order.paymentIsProcessing);

	const orderIsReadyToPay = order !== null;

	if (noOrderToPay) {
		throw redirect("/");
	}

	if (orderIsReadyToPay) {
		return (
			<>
				<div className="payment-container">
					<div className="payment-header">
						<div>
							<h2 className="with-line" style={{ fontSize: "1.5em", margin: "0" }}>
								Details and Payment
							</h2>
							<p style={{ fontSize: "1em", margin: "0 0 0.5em"  }}>
								Payment is taken securely through our gateway. All major cards
								are accepted.
							</p>
						</div>
						<AnchorButton text="Back" variant="ghost" style={{ marginTop: "0.8em", width: "8rem" }} href="/" />
					</div>
					<hr />
					<div className="payment-main">
						<CostBreakdown order={order} />
						<PaymentDetails order={order} stripePromise={stripePromise} />
					</div>
					<hr />
					<div className="payment-footer">
						<p>
							Please contact us on{" "}
							<a href="tel:+44 (0) 131 347 0100">+44 (0) 131 347 0100</a> or at{" "}
							<a href="mailto:members@icas.com">members@icas.com</a> if you have
							any questions.
						</p>
					</div>
				</div>
				<div className="details-container icas-details">
					<p>
						ICAS is the trading name of the Institute of Chartered Accountants
						of Scotland <br />
						Royal Charter number: RC000247
						<br /> CA House, 21 Haymarket Yards, Edinburgh, EH12 5BH <br />
						By completing this order, you agree to the ICAS{" "}
						<a href="https://www.icas.com/terms-and-conditions">
							terms and conditions
						</a>
					</p>
				</div>
			</>
		);
	}

	if (haveError) {
		if (notFound) {
			return (
				<>
					<div className="payment-container">
						<ErrorNotFound />
					</div>
				</>
			);
		}
		return (
			<>
				<div className="payment-container">
					<Error
						errorMessage="Request to load payment details failed."
						handleClick={handleErrorRetry}
					/>
				</div>
			</>
		);
	}

	return <LoadingLottie style={{ height: "25em", margin: "auto" }} />;
};
