import React, { FC } from "react";

type ImageProps = {
	image: string;
	alt: string;
	height: string;
	divCenter?: true;
	showImage?: true;
	styleToAdd?: object;
};

export const ImageIcon: FC<ImageProps> = ({
	image,
	alt,
	height,
	styleToAdd,
	divCenter = false,
	showImage = true,
}) => {
	let initialStyle = {};

	if (divCenter) {
		const center = { margin: "0 auto" };
		Object.assign(initialStyle, center);
	}
	if (!showImage) {
		const hideImageStyle = { display: "none" };
		Object.assign(initialStyle, hideImageStyle);
	}
	if (styleToAdd) {
		Object.assign(initialStyle, styleToAdd);
	}

	return (
		<div className="image-container" style={initialStyle}>
			<img src={image} alt={alt} style={{ height: height }}></img>
		</div>
	);
};
