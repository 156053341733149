import React, { FC, useCallback, useEffect, useState } from "react";
import {
	AnchorButton,
	FetchHelper,
	LoadingLottie,
	useAuthContext,
} from "@icasdigital/icas.core.reactcomponents";
import { useStripe } from "@stripe/react-stripe-js";
import { PaymentSuccessful } from "./PaymentSuccessful";
import { PaymentFailed } from "./PaymentFailed";
import { RedirectDestinations } from "../../types/RedirectDestinations";

type SCProps = {
	orderNumber: string;
	orderOrigin?: string;
	payment_intent_client_secret: string;
};

const renderHeaderMessage = (status: string) => {
	if (status === "succeeded") {
		return "Thank you";
	}
	if (status === "processing") {
		return "Payment is processing";
	}
	if (status === "requires_payment_method") {
		return "Card authorisation failed";
	}
	return "Something went wrong";
};

export const PaymentStatusOutcome: FC<SCProps> = ({
	orderNumber,
	orderOrigin,
	payment_intent_client_secret,
}) => {
	const { handleUnauth } = useAuthContext();
	const [status, setStatus] = useState<string>();
	const [redirectDestinations, setRedirectDestinations] =
		useState<RedirectDestinations>();

	const stripe = useStripe();

	//#region Request Handlers
	// Retrieve the publishable key from services
	const getRedirectDestinations = useCallback(async () => {
		const fetchUrl = "/lookup/redirectDestinations";
		const fetchResponse = await FetchHelper.getJSON(fetchUrl, handleUnauth);
		if (fetchResponse.ok) {
			const data: RedirectDestinations =
				fetchResponse.body as RedirectDestinations;
			setRedirectDestinations(data);
		}
	}, [handleUnauth]);
	//#endregion

	useEffect(() => {
		if (getRedirectDestinations) {
			getRedirectDestinations();
		}
	}, [getRedirectDestinations]);

	if (stripe && payment_intent_client_secret) {
		stripe
			.retrievePaymentIntent(payment_intent_client_secret)
			.then(({ paymentIntent }) => {
				if (paymentIntent?.status) {
					setStatus(paymentIntent.status);
				}
			});
	}

	const headerMessage = status ? renderHeaderMessage(status) : undefined;

	if (orderNumber && status && headerMessage && redirectDestinations) {
		//TODO: contact email address for non-renewal orders?
		const mailTo = `members@icas.com?subject=${orderNumber}`;
		const isSuccess = status === "succeeded";
		const isFailed =
			!isSuccess &&
			(status === "requires_payment_method" || status !== "processing");

		const isBackToRenewal =
			status === "succeeded" &&
			orderOrigin === "AnnualRenewal" &&
			redirectDestinations.renewalApp;
		return (
			<>
				<div className="payment-status" style={{ marginBottom: "0" }}>
					<div className="payment-header">
						<h2 style={{ fontSize: "1.75em", marginBottom: "0" }}>
							{headerMessage}
						</h2>
					</div>
					<hr />
					<div className="payment-main" style={{ alignItems: "center" }}>
						{isSuccess ? <PaymentSuccessful /> : null}
						{isFailed ? <PaymentFailed orderNumber={orderNumber} /> : null}
					</div>
					<hr />
					<div
						className="payment-footer"
						style={{
							justifyContent: "space-between",
							flexDirection: "row",
							alignItems: "center",
						}}
					>
						<p>
							Any questions? <a href={mailTo}>Get in touch with us</a>
						</p>
						{isBackToRenewal ? (
							<AnchorButton
								style={{ width: "11rem" }}
								href={redirectDestinations.renewalApp}
								text="Back to Renewal"
							/>
						) : null}

						{!isBackToRenewal && orderOrigin !== "AnnualRenewal" ? (
							<AnchorButton
								style={{ width: "11rem" }}
								href="/"
								text="Back to My Orders"
							/>
						) : null}
					</div>
				</div>
			</>
		);
	}
	return <LoadingLottie style={{ height: "25em", margin: "auto" }} />;
};
