import { createRoot } from "react-dom/client";
import { App } from "./App";
import { AuthProvider } from "@icasdigital/icas.core.reactcomponents";
import {
	Navigate,
	Route,
	createBrowserRouter,
	RouterProvider,
	createRoutesFromElements,
} from "react-router-dom";
import { PrivateRoute } from "@icasdigital/icas.core.reactcomponents";
import { PaymentContainer } from "./containers/PaymentContainer/PaymentContainer";
import "./custom.css";
import { TabContainer } from "./containers/TabContainer";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const orderPath = `/orders`;

const loginPath =
	"/authentication/login?returnUrl=" +
	encodeURIComponent(window.location.toString());

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" element={<App />}>
			<Route element={<PrivateRoute loginPath={loginPath} />}>
				<Route index element={<Navigate to={orderPath} />} />
				<Route path="orders" element={<TabContainer />} />
				<Route path="payment" element={<PaymentContainer />} />
			</Route>
		</Route>
	)
);

root.render(
	<AuthProvider>
		<RouterProvider router={router} />
	</AuthProvider>
);
