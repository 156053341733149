import React, { FC, CSSProperties } from "react";
import { useLottie } from "lottie-react";
import paymentFailed from "../../assets/payment-failed.json";

export const PaymentFailedLottie: FC<{
	style?: CSSProperties;
	loop?: boolean;
	autoplay?: boolean;
}> = ({ children, style, loop = true, autoplay = true }) => {
	const options = {
		animationData: paymentFailed,
		loop: loop,
		autoplay: autoplay,
	};

	const { View } = useLottie(options, style);

	return View;
};
