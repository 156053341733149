import React, { FC } from 'react';
import { Error } from "@icasdigital/icas.core.reactcomponents";

export const ErrorNotFound: FC = () : JSX.Element => {

	const returnToIndex = () => {
		window.location.assign("/");
	}

	return (
		<Error
			errorCode={404}
			handleClick={returnToIndex}
			buttonText="Return to My Orders"
		/>);
};
