import React, { FC } from "react";
import { Order } from "../../types/Order";
import OrderIcon from "../../assets/orderIcon.png";
import { ColumnType, OrderItemDetail } from "../OrderItem/OrderItemDetail";

type CBProps = {
	order: Order;
};

const columns = [
	{
		objectName: "orderNumber",
		displayName: "Reference",
		type: ColumnType.string,
	},
	{
		objectName: "product",
		displayName: "Product details",
		type: ColumnType.string,
	},
	{
		objectName: "totalAmount",
		displayName: "Total amount",
		type: ColumnType.money,
	},
	{
		objectName: "outstandingAmount",
		displayName: "Outstanding balance due",
		type: ColumnType.money,
	},
];

export const CostBreakdown: FC<CBProps> = ({ order }) => {
	// TODO: render breakdown of details, requires API shape
	const orderDetails: Record<string, any> = {};
	order.salesOrderDetails.forEach((sod, index) => {
		if (index === 0) {
			return (orderDetails[
				"product"
			] = `${sod.productFriendlyName} x ${sod.quantity}`);
		}
		orderDetails["product"] += `, ${sod.productFriendlyName} x ${sod.quantity}`;
	});

	const fullDetails: Record<string, any> = { ...order, ...orderDetails };

	return (
		<div className="payment-details">
			<div
				style={{
					display: "inline-flex",
					alignItems: "center",
					paddingLeft: "1.5em",
				}}
			>
				<h3 className="with-line" style={{ margin: "0", fontSize: "1.35em" }}>
					Order Summary:
				</h3>
			</div>
			<OrderItemDetail record={fullDetails} columns={columns} icon={OrderIcon} />
		</div>
	);
};
