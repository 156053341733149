import {
	LoadingStatus,
	useAuthContext,
	FetchResponse,
	FetchHelper,
} from "@icasdigital/icas.core.reactcomponents";
import { Order } from "../types/Order";
import { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import { OrderType } from "./OrderContainer/OrderContainer";

const initialOrders: Order[] = [];

export const useGetOrders = ({ orderType }: { orderType: OrderType }) => {
	//#region State and Context Management
	const [orders, setOrders] = useState(initialOrders);
	const [loadingStatus, setLoadingStatus] = useState(
		LoadingStatus.IsNotLoading
	);
	const [fetchOrders, setFetchOrders] = useState(true);

	const { userId, handleUnauth } = useAuthContext();

	const navigate = useNavigate();
	const [haveError, setHaveError] = useState(false);

	const handleResponse = useCallback(
		(fetchResponse: FetchResponse): void => {
			if (fetchResponse.ok) {
				const data: Array<Order> =
					fetchResponse.body as unknown as Array<Order>;
				const openRenewalOrder = data.find(
					(o) =>
						o.origin === "AnnualRenewal" &&
						!(o.isFulfilled || o.paymentIsProcessing)
				);
				if (openRenewalOrder) {
					navigate(`/payment?orderNumber=${openRenewalOrder.orderNumber}`);
				} else {
					setOrders(data);
					setLoadingStatus(LoadingStatus.LoadedSuccessfully);
				}
			} else if (fetchResponse.status === 401) {
				setLoadingStatus(LoadingStatus.EndedWithError);
				handleUnauth();
			} else {
				setLoadingStatus(LoadingStatus.EndedWithError);
				setHaveError(true);
			}
		},
		[handleUnauth, navigate]
	);

	const getOrders = useCallback(async () => {
		setFetchOrders(false);
		setLoadingStatus(LoadingStatus.IsLoading);
		const isFulfilled = orderType === OrderType.Fulfilled;
		const fetchURL = `/members/${userId}/orders?isFulfilled=${isFulfilled}`;
		const fetchResponse = await FetchHelper.getJSON(
			fetchURL.toString(),
			handleUnauth
		);
		handleResponse(fetchResponse);
	}, [handleResponse, handleUnauth, orderType, userId]);

	useEffect(() => {
		if (fetchOrders && userId && orderType && !haveError) {
			getOrders();
		}
	}, [fetchOrders, getOrders, orderType, userId, haveError]);

	const handleErrorRetry = () => {
		setFetchOrders(true);
		setHaveError(false);
	};

	return { orders, haveError, loadingStatus, handleErrorRetry };
};
