import { Order } from "../../types/Order";
import { ColumnType, OrderItemDetail } from "./OrderItemDetail";
import "./OrderItemStyle.css";
import OrderIcon from "../../assets/orderIcon.png";

type OIProps = {
	order: Order;
};

const columns = [
		{
			objectName: "orderNumber",
			displayName: "Reference",
			type: ColumnType.string,
		},
		{
			objectName: "dateFulfilled",
			displayName: "Date Fulfilled",
			type: ColumnType.date,
		},
		{
			objectName: "totalAmount",
			displayName: "Total Amount",
			type: ColumnType.money,
		},
	];

export const FulfilledOrderItem = ({ order }: OIProps) => {

	return (
		<div className="order">
			<OrderItemDetail record={order} columns={columns} icon={OrderIcon} />
		</div>
	);
};
