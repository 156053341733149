import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button } from "@icasdigital/icas.core.reactcomponents";
import React, { FC, useCallback, useEffect, useState } from 'react';
import { renderGBP } from '../../utils/Formatting';

type CheckoutFormProps = {
	orderNumber: string,
	orderOrigin: string,
	orderOutstandingAmount: number
}

export const CheckoutForm: FC<CheckoutFormProps> = ({
	orderNumber,
	orderOrigin,
	orderOutstandingAmount: amountToPay
}) => {
	const [paymentSubmitted, setPaymentSubmitted] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string>();
	const stripe = useStripe();
	const stripeElements = useElements();

	const submitPaymentToStripe = useCallback(async () => {
		if (stripe && stripeElements) {
			const origin = orderOrigin !== 'Other' ? '&origin=' + orderOrigin : '';  
			const { error } = await stripe.confirmPayment({
				elements: stripeElements,
				confirmParams: {
					return_url: 'https://' + window.location.host + '/payment?orderNumber=' + orderNumber + origin
				}
			});
			if (error) {
				setErrorMessage(error.message);
				setPaymentSubmitted(false);
			}
		}
	}, [stripe, stripeElements, orderNumber, orderOrigin]);

	useEffect(() => {
		if (paymentSubmitted) {
			submitPaymentToStripe();
		}
	}, [paymentSubmitted, submitPaymentToStripe]);

	// Use the Stripe template to add card details to Stripe -- Changed
	const storeCardDetails = () => {
		setPaymentSubmitted(true);
	};

	return (
		<>
			<div>
				<form name="checkout">
					<PaymentElement	/>
				</form>
			</div>
			<span>
			<div className="payment-button" style={{marginTop: "1.5em"}}>
				<span>
					<p>
						Total to pay:{" "}
						<strong>{renderGBP(amountToPay)}</strong>
					</p>
				</span>
				<Button
					id="checkoutForm"
					text={"Pay Now"}
					style={{width: "11em"}}
					disabled={paymentSubmitted}
					onClick={storeCardDetails}
				/>
			</div>
			<p style={{ color: "red" }}>{errorMessage}</p>
			</span>
		</>
	);
};

