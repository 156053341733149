import React, { FC } from "react";
import { useAuthContext } from "@icasdigital/icas.core.reactcomponents";
import { PaymentSuccessfulLottie } from "./PaymentSuccessfulLottie";

export const PaymentSuccessful: FC = () => {
	const { userEmail } = useAuthContext();

	return (
		<>
			<PaymentSuccessfulLottie
				style={{ height: "10em" }}
			/>
			<div>
				<h3>Your payment has been received.</h3>
				<p>
					A confirmation email and any receipts will be sent to{" "}
					{userEmail} within 24 hours.
				</p>
			</div>
		</>
	);
};
